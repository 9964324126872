import { createStore } from 'vuex';
import snack from './snack';
import liveEvent from './liveEvent.js';
import axios from '../js/axios.js';
import { toggleSideMenu, closeSideMenu } from './toggleSideMenu';

const store = createStore({
    modules: {
        snack,
        liveEvent
    },

    state: {
        user: null,
        isAuthenticated: !!localStorage.getItem('bearer_token'),
        messageAfterRedirect: {
            message: null,
            type: null
        },
        isSideMenuOpen: false,
        liveEventData:null,
        eventHomePageLists:{
            featured:null, // slider or recent
            recent:null,
            liveSchedule:null,
            onDemand:null,
        },
        onDemandCategories:null,
        playerData:null,
        reloadAds:false,
        orderByDate:false,
        isLoggedInChat:false,
        currentOrganization: null
    },

    mutations: {
        setUser(state, userData) {
            state.user = userData;
            state.isAuthenticated = true;
        },
        clearUser(state) {
            state.user = null;
            state.isAuthenticated = false;
            localStorage.removeItem('bearer_token');
        },
        setIsAuthenticated(state, isAuthenticated) {
            state.isAuthenticated = isAuthenticated;
        },
        setMessageAfterRedirect(state, messageObject) {
            state.messageAfterRedirect = messageObject
        },
        clearMessageAfterRedirect(state) {
            state.messageAfterRedirect.message = null;
            state.messageAfterRedirect.type = null;
        },
        setLiveEvent(state,event){
            state.liveEventData = event;
        },
        setPlayerData(state,event){
            state.playerData = event;
        },
        setCurrentOrganization(state, event){
            state.currentOrganization = event;
        },
        setonDemandCategories(state,event){
            state.onDemandCategories = event;
        },
        // setReloadAds(state,event){
        //     state.reloadAds = !state.reloadAds;
        // },
        toggleSideMenu,
        closeSideMenu,
    },

    actions: {
        async fetchUser({ commit }) {
            await axios.get('/user')
                .then((response) => {
                    commit('setUser', response.data);
                }).catch((error) => {
                    console.log(error);
                    commit('clearUser');
                });
        },
        async logOutUser({ commit }) {
            localStorage.removeItem('bearer_token');
            commit('clearUser');
        },
        async logInUser({ commit }) {
            localStorage.removeItem('bearer_token');
            commit('clearUser');
        },
    },
});

export default store;