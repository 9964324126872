<template>
  <div class="formInput">
    <label :style="labelAdditionalStyles">
      {{ labelText }} <span v-if="showStar" class="star">*</span>
    </label>

    <CustomCheckBox
      v-if="shouldShowCheckBox"
      :isChecked="isCheckBoxChecked"
      :readonly="readonly"
      @checkbox-clicked="toggleCheckbox"
    />
    
    <template v-if="showItem">
      <input
        v-if="shouldShowInput"
        v-model="inputValue"
        :type="inputType"
        :style="inputAdditionalStyles"
        :placeholder="placeholder"
        @input="updateParentValue"
        :readonly="readonly"
      />
      
      <textarea
        v-if="shouldShowTextarea"
        v-model="inputValue"
        :style="inputAdditionalStyles"
        :readonly="readonly"
      ></textarea>

      <Dropdown
        v-if="shouldShowDropdown"
        :options="dropdownOptions"
        :placeholder="placeholder"
        :style="inputAdditionalStyles"
        :defaultValueById="dropdownDefaultOptionId"
        :searchable="searchable"
        :readonly="readonly"
        @selected="onOptionSelected($event.id)"
      />
    </template>
  </div>
</template>

<script>
import CustomCheckBox from './CustomCheckBox.vue';
import Dropdown from '../SelectDropdown.vue';
import { readonly } from 'vue';

export default {
  components: {
    CustomCheckBox,
    Dropdown,
  },
  props: {
    inputType: {
      type: String,
      default: 'text',
    },
    labelText: {
      type: String,
      default: 'Label text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    togleWithCheckBox: {
      type: Boolean,
      default: false,
    },
    isCheckBoxChecked: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    dropdownOptions: {
      type: Array,
      default: []
    },
    dropdownDefaultOptionId: {
      type: Number,
      default: null
    },
    showStar: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    labelAdditionalStyles: {},
    inputAdditionalStyles: {},

  },
  data() {
    return {
      inputValue: this.modelValue,
      showItem: true,
    };
  },
  computed: {
    shouldShowCheckBox() {
      return this.togleWithCheckBox || this.inputType === 'checkbox';
    },
    shouldShowInput() {
      return ['text', 'number'].includes(this.inputType);
    },
    shouldShowTextarea() {
      return this.inputType === 'textarea';
    },
    shouldShowDropdown() {
      return this.inputType === 'dropdown';
    },
  },
  methods: {
    toggleCheckbox(showItem) {
      this.showItem = showItem;
      this.$emit('on-checkbox-toggle', showItem);
    },
    updateParentValue() {
      this.$emit('update:modelValue', this.inputValue);
    },
    onOptionSelected(selectedItem) {
      this.$emit('selected', selectedItem);
    },
  },
  mounted() {
    if (this.togleWithCheckBox) this.showItem =false;
 
    if(this.isCheckBoxChecked) this.showItem = true;
    
    if(this.dropdownDefaultOptionId){
      this.onOptionSelected(this.dropdownDefaultOptionId)
    }
  },
  watch: {
    modelValue(newValue, oldValue) {
      this.inputValue = newValue;
    },
    dropdownDefaultOptionId(newValue,oldValue){
      this.showItem = true;
    },
    
    
  
  }
};
</script>


<style scoped>
.formInput {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}

.formInput label {
  font-size: 13px !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #464545;
  text-align: right;
  width: 180px !important;
}

.formInput label>.star {
  color: red;
  font-size: 15px;
  margin-left: 3px;
}

.formInput input {
  height: 30px;
}

.formInput input,
.formInput textarea {
  width: 100%;
  padding: 5px 12px;
  outline: none;
  border-radius: 5px;
  box-shadow: 0px 0px 6px #0000000A;
  border: 0.5px solid #ECECEC;
  font-size: 12px;
  color: #464545;
}

::-webkit-input-placeholder {
  color: #d0d0d0;
}

:-moz-placeholder {
  color: #d0d0d0;
  opacity: 1;
}

::-moz-placeholder {
  color: #d0d0d0;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #d0d0d0;
}

.formInput input:focus,
.formInput textarea:focus {
  border: 0.5px solid #A9DDF3;
  box-shadow: 0px 0px 6px #A9DDF3;
}
</style>

